var mapplicInit = function(map) {
    var $map = $(map);
    var mapSource = map.getAttribute('data-map-source');

    if (typeof mapSource !== 'undefined' && mapSource !== null) {
        $map.mapplic({
            source: JSON.parse(mapSource),
            height: 800,
            sidebar: false,
            minimap: false,
            zoombuttons: true,
            fullscreen: false,
            maxscale: 2,
            developer: false,
            hovertip: false,
            mousewheel: false,
            deeplinking: false
        });
        $map.data('mapplic').hideLocation();
        setTimeout(
            function() {
                $map.data('mapplic').moveTo(0.5,0.5,1);
            },
            500
        );

        $map.on('locationopened', function(e, self) {
            var mapHolder = getClosest(e.currentTarget, '.js-destinationsmap-holder');
            if (typeof mapHolder !== 'undefined' && mapHolder !== null) {
                if (mapHolder.classList && !mapHolder.classList.contains('is-clicked')) {
                    mapHolder.classList.add('is-clicked');
                }
            }
        });

        return $map;
    }

    return null;
};
